import BasePage, { IBasePageProps, SeoProps } from './BasePage';
import React, { useEffect } from 'react';
import { NextPage } from 'next/types';
import { useUserData } from 'state/userData';
import useSWR from 'swr';
import getPageByPath from 'api/getPageByPath';
import { useHeader } from 'state/useHeader';
import camelcaseKeys from 'camelcase-keys';
import { ComponentHeader } from 'types/componentTypes';
import { ComponentWrapper } from './BasePage.styled';
import Header from 'components/Header';
import Footer from 'components/Footer';

type Props<P> = P & IBasePageProps;
declare const window: Window & { dataLayer: Record<string, unknown>[] };

interface getPageByPathResponse {
    component_name: string;
    component_props: {
        header: ComponentHeader;
    };
}

export function basePageWrap<P>(
    Component: NextPage<Props<P>>,
    seo?: Partial<SeoProps>
) {
    return (props: Props<P>) => {
        const { isLoggedIn, user } = useUserData();
        const userId = user?.id;

        const setHeader = useHeader((state) => state.setHeader);
        const storedHeader = useHeader((state) => state.header);

        const headerFetcher = async () => {
            const { data } = await getPageByPath<getPageByPathResponse>({
                url: '/',
            });
            return data.component_props.header;
        };

        const { data: header } = useSWR(`header/${isLoggedIn}`, headerFetcher, {
            revalidateOnFocus: false,
        });

        useEffect(() => {
            if (!window.dataLayer) return;

            window.dataLayer.push({
                value: userId,
                event: 'pageView',
            });
        }, []);

        useEffect(() => {
            if (!header) return;

            const modifiedHeader = camelcaseKeys(header, { deep: true });

            setHeader(modifiedHeader);
        }, [isLoggedIn, header]);

        return (
            <BasePage {...props} seo={seo} _class={Component.name}>
                <ComponentWrapper>
                    <Header {...storedHeader} />

                    <Component {...props} />

                    <Footer />
                </ComponentWrapper>
            </BasePage>
        );
    };
}

export default BasePage;
