import { styled } from '@mui/material';
import { transientOptions } from 'helpers/transientOptions';

export const ValidationElement = styled(
    'p',
    transientOptions
)<{ $withError: boolean }>(({ theme, $withError }) => ({
    ...theme.fonts.caption,
    gap: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $withError ? theme.colors.errorText : theme.colors.secondaryText,
    flexShrink: '0',
}));
