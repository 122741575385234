import StyledPopper from 'shared/formElements/StyledPopper';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
    CSSProperties,
    ChangeEvent,
    ClipboardEventHandler,
    Dispatch,
    SetStateAction,
    useRef,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import InputWarningMessage from '../../../components/CreateVacancy/Components/InputWarningMessage';
import { StyledInput, ValidationRow, Wrapper } from './CustomInput.styled';
import { onKeyDownNumbersOnly } from 'helpers/onKeyDownNumbersOnly';
import {
    IconButton,
    InputAdornment,
    InputBaseComponentProps,
} from '@mui/material';
import { hasLetters, hasNumbers } from 'utils/passwordValidators';
import ValidationRule from './Components/ValidationRule';
import { debounce, get } from 'lodash';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

interface RoundedInputProps {
    placeholder?: string;
    inputFormKey: string;
    label: string;
    warningMessage?: string;
    endAdornment?: JSX.Element;
    startAdornment?: JSX.Element;
    borderRadius?: number;
    withAutocomplete?: boolean;
    setOnFocus?: Dispatch<SetStateAction<boolean>>;
    isOnFocus?: boolean;
    numbersOnly?: boolean;
    type?: React.InputHTMLAttributes<unknown>['type'];
    blockPaste?: boolean;
    inputProps?: InputBaseComponentProps | undefined;
    sx?: CSSProperties;
    wrapStyle?: { [key: string]: string };
    disabled?: boolean;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    withPasswordValidation?: boolean;
    helperText?: string;
    withError?: boolean;
    withDebounce?: boolean;
    withEye?: boolean;
}

const CustomInput: React.FC<RoundedInputProps> = ({
    placeholder = '',
    label,
    inputFormKey,
    warningMessage,
    helperText,
    endAdornment,
    startAdornment,
    borderRadius = 50,
    children,
    withAutocomplete = false,
    numbersOnly = false,
    setOnFocus,
    isOnFocus,
    type = 'text',
    blockPaste = false,
    inputProps,
    sx,
    wrapStyle,
    disabled = false,
    autoComplete = 'off',
    onKeyDown,
    withPasswordValidation = false,
    withError = false,
    withDebounce = false,
    withEye,
}) => {
    const [inputValue, setInputValue] = useState('');

    const [inputType, setInputType] = useState(type);
    const [isFocusLost, setIsFocusLost] = useState(false);
    const {
        register,
        formState: { isSubmitting, errors },
        trigger,
    } = useFormContext();

    const errorText = get(errors, inputFormKey)?.message;

    const {
        ref: registerRef,
        onChange,
        ...registerRest
    } = register(inputFormKey);

    const onChangeHandler = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setInputValue(e.target.value);
        onChange(e);
    };

    const debouncedOnChange = debounce(onChangeHandler, 300);

    const jobTitleAnchor = useRef<HTMLDivElement | HTMLInputElement | null>(
        null
    );

    useOnClickOutside(
        jobTitleAnchor,
        () => {
            if (setOnFocus) setOnFocus(false);
        },
        'liPopper'
    );

    const blockPasteHandler: ClipboardEventHandler<HTMLInputElement> = (e) => {
        if (blockPaste) e.preventDefault();
    };

    const ShowPasswordButton = () => (
        <InputAdornment position="end">
            <IconButton
                onClick={() =>
                    setInputType((value) =>
                        value === 'password' ? 'text' : 'password'
                    )
                }
                edge="end">
                {inputType === 'password' ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        </InputAdornment>
    );

    const InputEndAdornment = () => {
        return (
            <>
                {endAdornment && endAdornment}
                {withEye && <ShowPasswordButton />}
            </>
        );
    };

    return (
        <Wrapper sx={wrapStyle}>
            <StyledInput
                InputProps={{
                    endAdornment: <InputEndAdornment />,
                    startAdornment: startAdornment ? startAdornment : null,
                    sx: { borderRadius: `${borderRadius}px`, ...sx },
                }}
                {...registerRest}
                name={inputFormKey}
                type={inputType}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                onKeyDown={numbersOnly ? onKeyDownNumbersOnly : undefined}
                placeholder={placeholder}
                label={label ? label : null}
                onFocus={() => (setOnFocus ? setOnFocus(true) : null)}
                autoComplete={autoComplete}
                ref={(e) => {
                    registerRef(e);
                    jobTitleAnchor.current = e;
                }}
                onPaste={blockPasteHandler}
                inputProps={inputProps}
                disabled={disabled || isSubmitting}
                error={!!errorText || withError}
                helperText={
                    withPasswordValidation ? null : errorText || helperText
                }
                onKeyDownCapture={onKeyDown}
                onBlur={() => {
                    trigger(inputFormKey);
                    setIsFocusLost(true);
                }}
                onChange={(e) => {
                    withDebounce ? debouncedOnChange(e) : onChangeHandler(e);
                    !!errorText && trigger(inputFormKey);
                }}
                role="textbox"
            />

            {withPasswordValidation && (
                <ValidationRow>
                    <ValidationRule
                        ruleText="9+ characters"
                        isValidRule={inputValue.length >= 9}
                        isFocusLost={isFocusLost}
                    />
                    <ValidationRule
                        ruleText="Number"
                        isValidRule={hasNumbers(inputValue)}
                        isFocusLost={isFocusLost}
                    />
                    <ValidationRule
                        ruleText="Letters (uppercase & lowercase)"
                        isValidRule={hasLetters(inputValue)}
                        isFocusLost={isFocusLost}
                    />
                </ValidationRow>
            )}

            {withAutocomplete && isOnFocus && (
                <StyledPopper isOpen={isOnFocus} anchorEl={jobTitleAnchor}>
                    {children}
                </StyledPopper>
            )}

            {warningMessage && <InputWarningMessage message={warningMessage} />}
        </Wrapper>
    );
};

export default CustomInput;
