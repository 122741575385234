import { styled } from '@mui/material';

export const Component = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

export const Layout = styled('div')(({ theme }) => ({
    maxWidth: theme.breakpoints.values.desktop,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
}));
