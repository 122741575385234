import { CSSProperties, FC } from 'react';
import { Component, Layout } from './AppWrapper.styled';

interface Props {
    children?: React.ReactNode;
    sx?: CSSProperties;
}

export const LayoutWrapper: FC<Props> = ({ children, sx }) => {
    return (
        <Component>
            <Layout sx={sx}>{children}</Layout>
        </Component>
    );
};
