import { transientOptions } from './../../../helpers/transientOptions';
import { styled, TextField } from '@mui/material';

export const StyledInput = styled(
    TextField,
    transientOptions
)(({ theme, error }) => ({
    width: '100%',

    '& .MuiInputBase-root': {
        ...theme.fonts.body_2,
    },

    '& .MuiInputBase-input': {
        padding: '12.5px 17px',
    },

    '& ::placeholder': {
        color: theme.colors.secondaryColor_7,
    },

    '& label.Mui-focused': {
        color: error ? theme.colors.errorText : theme.colors.primaryColor,
    },

    '& ::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
    },

    '& [type=number]': {
        MozAppearance: 'textfield',
    },
}));

export const Wrapper = styled('div')(() => ({
    width: '100%',
}));

export const ValidationRow = styled('div')(() => ({
    gap: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '5px',
    flexWrap: 'wrap',
}));
