import React from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';
const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));

export interface SeoProps {
    seoHtmlTitle: string;
    seoMetaDescription: string;
    seoOgTitle: string;
    seoOgDescription: string;
    seoOgUrl: string;
    seoTwitterTitle: string;
    seoTwitterDescription: string;
    seoMetaRobots: string;
    seoOgImage?: string;
    seoOgType?: string;
    seoTwitterUrl?: string;
    seoTwitterImage?: string;
    canonicalLink?: string;
}
export interface IBasePageProps {
    title: string;
    lastPublishedAt: string;
    seoTitle: string;
    searchDescription: string;
    seo?: Partial<SeoProps>;

    siteSetting: {
        gtmId: string;
    };

    children?: React.ReactNode;
    wagtailUserbar?: {
        html: string;
    };
}

const BasePage = ({ children, seo, wagtailUserbar }: IBasePageProps) => {
    const {
        seoHtmlTitle,
        seoMetaDescription,
        seoOgTitle,
        seoOgDescription,
        seoOgUrl,
        seoOgImage,
        seoOgType,
        seoTwitterTitle,
        seoTwitterDescription,
        seoTwitterUrl,
        seoTwitterImage,
        seoMetaRobots,
        canonicalLink,
    } = seo || {};

    return (
        <>
            <Head>
                <title>{seoHtmlTitle}</title>
                {!!seoMetaDescription && (
                    <meta name="description" content={seoMetaDescription} />
                )}
                {!!seoOgTitle && (
                    <meta property="og:title" content={seoOgTitle} />
                )}
                {!!seoOgDescription && (
                    <meta
                        property="og:description"
                        content={seoOgDescription}
                    />
                )}
                {!!seoOgUrl && <meta property="og:url" content={seoOgUrl} />}
                {!!seoOgImage && (
                    <meta property="og:image" content={seoOgImage} />
                )}
                {!!seoOgType && <meta property="og:type" content={seoOgType} />}
                {!!seoTwitterTitle && (
                    <meta property="twitter:title" content={seoTwitterTitle} />
                )}
                {!!seoTwitterDescription && (
                    <meta
                        property="twitter:description"
                        content={seoTwitterDescription}
                    />
                )}
                {!!seoTwitterUrl && (
                    <meta property="twitter:url" content={seoTwitterUrl} />
                )}
                {!!seoTwitterImage && (
                    <meta property="twitter:image" content={seoTwitterImage} />
                )}
                <meta name="robots" content={seoMetaRobots} />
                {!!canonicalLink && (
                    <link rel="canonical" href={canonicalLink} />
                )}
            </Head>
            <div className="BasePage" style={{ width: '100%' }}>
                {children}
            </div>
            {!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
        </>
    );
};

const { serverRuntimeConfig } = getConfig();

const NEXT_PUBLIC_ROBOTS_TAG =
    process.env.NEXT_PUBLIC_ROBOTS_TAG ||
    serverRuntimeConfig.NEXT_PUBLIC_ROBOTS_TAG ||
    getCachedRuntimeConfig()?.NEXT_PUBLIC_ROBOTS_TAG;

BasePage.defaultProps = {
    seo: {
        seoHtmlTitle: '',
        seoMetaDescription: '',
        seoOgTitle: '',
        seoOgDescription: '',
        seoOgUrl: '',
        seoOgImage: '',
        seoOgType: '',
        seoTwitterTitle: '',
        seoTwitterDescription: '',
        seoTwitterUrl: '',
        seoTwitterImage: '',
        seoMetaRobots: NEXT_PUBLIC_ROBOTS_TAG || '',
        canonicalLink: '',
    },
    wagtailUserbar: { html: '' },
};

export default BasePage;
