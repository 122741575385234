export const onKeyDownNumbersOnly = (
    event: React.KeyboardEvent<HTMLInputElement>
) => {
    const allowedKeys = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        'Enter',
    ];
    const allowedChars = /[0-9]/;

    if (!allowedKeys.includes(event.key) && !allowedChars.test(event.key)) {
        event.preventDefault();
    }
};
