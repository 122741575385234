import axiosInstance from './axiosConfig';

interface getPageByPathProps {
    url: string;
    params?: {
        [key: string]: number;
    };
}

const getPageByPath = <T>({ url, params }: getPageByPathProps) =>
    axiosInstance.get<T>(`/wt/api/nextjs/v1/page_by_path/?html_path=${url}`, {
        withCredentials: true,
        params: { ...params },
    });

export default getPageByPath;
