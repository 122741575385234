import { styled } from '@mui/material';

export const ComponentWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100vh',
}));
