import { ComponentHeader } from 'types/componentTypes';
import { create } from 'zustand';

interface UserProfileState {
    header: ComponentHeader | undefined;

    setHeader: (data: ComponentHeader) => void;
}

export const useHeader = create<UserProfileState>((set) => ({
    header: undefined,

    setHeader: (headerData) =>
        set((store) => {
            return { ...store, header: headerData };
        }),
}));
